<template>
  <div id="banner_content" class="d-flex no-print">
    <img src="../assets/flag_and_logo.png" />
  </div>
</template>

<style lang="scss" scoped>
@import "../scss/site.scss";

/* 0 media q */
@media (min-width: 0px) {
  #banner_content {
    color: white;
    height: auto;
    background-color: #2b2b2b;
    max-width: 768px;
    width: 100%;
  }

  #banner_content img {
    width: 100%;
    height: auto;
  }
}

/* 768 media q */
@media (min-width: 768px) {
}

/* ipad lansdscape */
@media (min-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* 1025 media q */
@media (min-width: 1025px) {
}
</style>

<script>
/* eslint-disable */
import { bus_common } from "../mixins/bus_common";
export default {
  name: "banner",
  mixins: [bus_common],
  data() {
    return {};
  },
  methods: {
    message1() {
      alert("This button does nothing yet.");
    },
    init() {},
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
};
</script>
