<template>
  <div id="paypal-button"></div>
</template>

<style lang="scss" scoped>
@import "../scss/site.scss";
</style>

<script>
/* eslint-disable */
import { bus_common } from "../mixins/bus_common";
export default {
  name: "paypal_button",
  mixins: [bus_common],
  props: ["import", "description"],

  data() {
    return {};
  },
  methods: {
    message1() {
      alert("This button does nothing yet.");
    },
    init() {},
  },
  mounted() {
    var self = this;
    const totalImport = this.import;
    const ClientID =
      "ARsANw-IHv9pr38UJNVCpCFIpwDdmEpcXZUsWmsH-X8Yg4GGPI-uVWeOV_O_528Vrr4zoCW3Nxijfw4L";
    paypal.Button.render(
      {
        remoteRenderDomain: "*",
        env: "production",
        client: {
          production: ClientID,
        },

        locale: "en_US",
        style: {
          layout: "vertical",
          size: "responsive",
          color: "gold",
          shape: "pill",
          label: "pay",
        },

        commit: true,

        payment: function (data, actions) {
          return actions.payment.create({
            transactions: [
              {
                description: self.description,
                amount: {
                  total: totalImport,
                  currency: "USD",
                },
              },
            ],
          });
        },

        onAuthorize: function (data, actions) {
          return actions.payment.execute().then(function () {
            // self.swall_it(
            //   "Paypal Status",
            //   "Approved and completed!",
            //   "success",
            //   function () {
            //     self.$router.push("\completed");
            //   }
            // );

            console.log("authorised!");

            let pl = {
              regstId: self.$store.state.regstId,
              amount: parseFloat(totalImport),
            };
            self.$emit("paypal_done", pl);
            //   this.paypal_done(pl);
          });
        },
      },
      "#paypal-button"
    );

    console.log("Paypal button loaded");
  },
};
</script>
